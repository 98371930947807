.toggle-button {
  background-color: var(--dark-gray);
  width: fit-content;
  cursor: pointer;
  outline: none;
  padding: 2px;
  border: none;

  span {
    padding: 0.25rem 0.6rem;
    transition-duration: .2s;

    &[data-isactive=true] {
      background-color: var(--primary);
    }
  }
}