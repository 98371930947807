.card-wrapper {
  position: relative;
  background-color: #1c2a359e;
  backdrop-filter: blur(6px);
}
.dark {
  background-color: var(--dark-ebony-clay);
}
.light {
  background-color: var(--ebony-clay);
}