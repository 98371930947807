.maw {
  &-300 {
    max-width: 300px;
  }

  &-610 {
    max-width: 610px;
  }

  &-765 {
    max-width: 765px;
  }

  &-1180 {
    max-width: var(--container_width);
  }
}

.h {

  &-1 {
    height: 1px;
  }

  &-200{
    height: 200px;
  }

  &-400{
    height: 400px;
  }

  &-800{
    height: 800px;
  }
}

.mih {
  &-70 {
    min-height: 70px;
  }
}