.pa-1 {
  padding: 1rem;
}
.pa-2 {
  padding: 2rem;
}
.pa-3 {
  padding: 3rem;
}

.pt-1 {
  padding-top: 1rem;
}

.pt-2 {
  padding-top: 2rem;
}

.pt-3 {
  padding-top: 3rem;
}

.pv-1 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.pv-2 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.pv-3 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}
