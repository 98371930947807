.full-wh {
  width: 100%;
  height: 100%;
}

.full-w {
  width: 100%;
}

.full-h {
  height: 100%;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-s-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.o{
  &-h{
    overflow: hidden;
  }
  &-a{
    overflow: auto;
  }
}

.p {
  &-0 {
    padding: 0;
  }

  &-025 {
    padding: .25rem;
  }

  &-05 {
    padding: .5rem;
  }

  &-1 {
    padding: 1rem;
  }

  &-2 {
    padding: 2rem;
  }

  &t {
    &-0 {
      padding-top: 0;
    }

    &-05 {
      padding-top: 0.5rem;
    }

    &-1 {
      padding-top: 1rem;
    }

    &-2 {
      padding-top: 2rem;
    }

    &-3 {
      padding-top: 3rem;
    }
  }

  &r {
    &-0 {
      padding-right: 0;
    }

    &-05 {
      padding-right: 0.5rem;
    }

    &-1 {
      padding-right: 1rem;
    }

    &-2 {
      padding-right: 2rem;
    }

    &-3 {
      padding-right: 3rem;
    }
  }

  &b {
    &-0 {
      padding-bottom: 0;
    }

    &-05 {
      padding-bottom: 0.5rem;
    }

    &-1 {
      padding-bottom: 1rem;
    }

    &-2 {
      padding-bottom: 2rem;
    }

    &-3 {
      padding-bottom: 3rem;
    }
  }

  &l {
    &-0 {
      padding-left: 0;
    }

    &-05 {
      padding-left: 0.5rem;
    }

    &-1 {
      padding-left: 1rem;
    }

    &-2 {
      padding-left: 2rem;
    }

    &-3 {
      padding-left: 3rem;
    }
  }

  &v {
    &-025 {
      padding-top: .25rem;
      padding-bottom: .25rem;
    }

    &-05 {
      padding-top: .5rem;
      padding-bottom: .5rem;
    }

    &-075 {
      padding-top: .75rem;
      padding-bottom: .75rem;
    }

    &-1 {
      padding-top: 1rem;
      padding-bottom: 1rem;
    }

    &-2 {
      padding-top: 2rem;
      padding-bottom: 2rem;
    }

    &-3 {
      padding-top: 3rem;
      padding-bottom: 3rem;
    }
  }

  &h {
    &-025 {
      padding-right: .25rem;
      padding-left: .25rem;
    }

    &-05 {
      padding-right: .5rem;
      padding-left: .5rem;
    }

    &-075 {
      padding-right: .75rem;
      padding-left: .75rem;
    }

    &-1 {
      padding-right: 1rem;
      padding-left: 1rem;
    }

    &-15 {
      padding-right: 1.5rem;
      padding-left: 1.5rem;
    }

    &-2 {
      padding-right: 2rem;
      padding-left: 2rem;
    }

    &-3 {
      padding-right: 3rem;
      padding-left: 3rem;
    }
  }
}

.m {
  &-a {
    margin: auto;
  }
  &v {
    &-025 {
      margin-top: .25rem;
      margin-bottom: .25rem;
    }

    &-05 {
      margin-top: .5rem;
      margin-bottom: .5rem;
    }

    &-1 {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }

    &-15 {
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
    }

    &-2 {
      margin-top: 2rem;
      margin-bottom: 2rem;
    }
  }

  &h {
    &-a {
      margin-left: auto;
      margin-right: auto;
    }

    &-025 {
      margin-left: .25rem;
      margin-right: .25rem;
    }

    &-05 {
      margin-left: .5rem;
      margin-right: .5rem;
    }

    &-1 {
      margin-left: 1rem;
      margin-right: 1rem;
    }

    &-2 {
      margin-left: 2rem;
      margin-right: 2rem;
    }
  }

  &l {
    &-0 {
      margin-left: 0;
    }

    &-025 {
      margin-left: 0.25rem;
    }

    &-05 {
      margin-left: 0.5rem;
    }

    &-1 {
      margin-left: 1rem;
    }

    &-15 {
      margin-left: 1.5rem;
    }

    &-2 {
      margin-left: 2rem;
    }

    &-3 {
      margin-left: 3rem;
    }

    &-a {
      margin-left: auto;
    }
  }

  &r {
    &-0 {
      margin-right: 0;
    }

    &-025 {
      margin-right: 0.25rem;
    }

    &-05 {
      margin-right: 0.5rem;
    }

    &-1 {
      margin-right: 1rem;
    }

    &-2 {
      margin-right: 2rem;
    }

    &-a {
      margin-right: auto;
    }
  }

  &t {
    &-0 {
      margin-top: 0;
    }

    &-025 {
      margin-top: 0.25rem;
    }

    &-05 {
      margin-top: 0.5rem;
    }

    &-075 {
      margin-top: 0.75rem;
    }

    &-1 {
      margin-top: 1rem;
    }

    &-2 {
      margin-top: 2rem;
    }

    &-3 {
      margin-top: 3rem;
    }

    &-4 {
      margin-top: 4rem;
    }

    &-a {
      margin-top: auto;
    }
  }

  &b {
    &-0 {
      margin-bottom: 0;
    }

    &-025 {
      margin-bottom: .25rem;
    }

    &-05 {
      margin-bottom: 0.5rem;
    }

    &-1 {
      margin-bottom: 1rem;
    }

    &-2 {
      margin-bottom: 2rem;
    }

    &-3 {
      margin-bottom: 3rem;
    }

    &-a {
      margin-bottom: auto;
    }
  }
}

.i-f {
  display: inline-flex;
}

.f {
  display: flex;
}

.n {
  display: none;
}

.f,
.i-f {
  &.c {
    flex-direction: column;
  }

  &-ac {
    align-items: center;
  }

  &-je {
    justify-content: end;
  }

  &-jc {
    justify-content: center;
  }

  &-jb {
    justify-content: space-between;
  }
}

.fl-r {
  float: right;
}

.fw {
  &-b {
    font-weight: 600;
  }
}

.pos {
  &-a {
    position: absolute;
  }
  
  &-r {
    position: relative;
  }
}

.t {
  &-m1 {
    top: -1rem;
  }

  &-m2 {
    top: -2rem;
  }

  &-m3 {
    top: -3rem;
  }

  &-m4 {
    top: -4rem;
  }

  &-m5 {
    top: -5rem;
  }

  &-m6 {
    top: -6rem;
  }
}

.r {

  &-0 {
    right: 0;
  }
  &-05 {
    right: 0.5rem;
  }
}

.z {
  &-10 {
    z-index: 10;
  }

  &-100 {
    z-index: 100;
  }
}

.cur {
  &-d {
    cursor: default;
  }

  &-p {
    cursor: pointer;
  }

}

.bottom {
  &-0 {
    bottom: 0;
  }
}