@import './colors';
@import './dimensions';
@import './helpers';
@import './normalize';
@import './reset';
@import './common';
@import './padding';
@import './width';
@import './buttons';
@import './typography';
@import "./hover";
@import './media';

body {
  overflow-x: hidden !important;

  iframe[style*="z-index: 2147483647"] {
    display: none!important;
  }
}


.br {
  &-0 {
    border: none;
  }

  &-8 {
    border-radius: 8px;
  }

  &-12 {
    border-radius: 12px;
  }

  &-24 {
    border-radius: 24px;
  }
}

.mr-f-05 {
  &:first-child {
    margin-right: .5rem;
  }
}

.ml-l-05 {
  &:last-child {
    margin-left: .5rem;
  }
}

.w {
  &-fc {
    width: fit-content;
  }

  &-100 {
    width: 100%;
  }
}

.rg {
  &-1 {
    row-gap: 1rem;
  }

  &-2 {
    row-gap: 2rem;
  }

  &-3 {
    row-gap: 3rem;
  }
}

.cg {

  &-05 {
    column-gap: .5rem;
  }

  &-1 {
    column-gap: 1rem;
  }

  &-2 {
    column-gap: 2rem;
  }

  &-3 {
    column-gap: 3rem;
  }
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes skeleton-animation {
  100% {
    transform: translateX(100%);
  }
}

@mixin skeleton-gradient {
  position: relative;
  overflow: hidden;
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
                    90deg,
                    rgba(91, 105, 141, 0) 0,
                    rgba(94, 131, 225, 0.25) 25%,
                    rgba(94, 131, 225, 0.5) 60%,
                    rgba(91, 105, 141, 0)
    );
    animation-name: skeleton-animation;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    content: '';
  }
}
