.range {
    &__notification {
        position: relative;
        padding: 8px;
        border-radius: 0 0 8px 8px;
        text-align: center;

        &.out-of-range {
            background-color: #292305;
            color: #f3a91b;
            border-top: 1px solid #583b0d;
        }

        &.error {
            background-color: #290505;
            color: #f31b1b;
            border-top: 1px solid #580d0d;
        }
    }

    &__chart {
        width: 542px;
        background-color: var(--ebony-clay);
        border-radius: 8px;
        border: 1px solid #233d65;
    }
}


@media screen and (max-width: 960px) {
    .range {
        &__chart {
            width: 100%;
        }
    }
  }
  
  @media screen and (max-width: 720px) {
  
  }
  
  @media screen and (max-width: 500px) {
  
  }