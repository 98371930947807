:root {
  --extra-small: 500px;
  --small: 720px;
  --medium: 960px;
  --large: 1280px;
}

.hide {
  &-m {
    @media screen and (max-width: 960px) {
      display: none;
    }
  }

  &-s {
    @media screen and (max-width: 720px) {
      display: none;
    }
  }

  &-xs {
    @media screen and (max-width: 500px) {
      display: none;
    }
  }
}

.show {
  &-m {
    @media screen and (max-width: 960px) {
      display: inline-flex;
    }
  }
  &-xs {
    @media screen and (min-width: 501px) {
      display: none;
    }
  }

  &-s {
    @media screen and (min-width: 721px) {
      display: none;
    }
  }
}

.mm {
  @media screen and (max-width: 960px) {
    &_fd {
      &-c {
        flex-direction: column;
      }
      &-cr {
        flex-direction: column-reverse;
      }
    }

    &_p {
      &-0 {
        padding: 0;
      }

      &-025 {
        padding: .25rem;
      }

      &-05 {
        padding: .5rem;
      }

      &-1 {
        padding: 1rem;
      }

      &-2 {
        padding: 2rem;
      }

      &t {
        &-0 {
          padding-top: 0;
        }

        &-05 {
          padding-top: 0.5rem;
        }

        &-1 {
          padding-top: 1rem;
        }

        &-2 {
          padding-top: 2rem;
        }

        &-3 {
          padding-top: 3rem;
        }

        &-4 {
          padding-top: 4rem;
        }

        &-5 {
          padding-top: 5rem;
        }
      }

      &r {
        &-0 {
          padding-right: 0;
        }

        &-05 {
          padding-right: 0.5rem;
        }

        &-1 {
          padding-right: 1rem;
        }

        &-2 {
          padding-right: 2rem;
        }

        &-3 {
          padding-right: 3rem;
        }
      }

      &b {
        &-0 {
          padding-bottom: 0;
        }

        &-05 {
          padding-bottom: 0.5rem;
        }

        &-1 {
          padding-bottom: 1rem;
        }

        &-2 {
          padding-bottom: 2rem;
        }

        &-3 {
          padding-bottom: 3rem;
        }

        &-4 {
          padding-bottom: 4rem;
        }
      }

      &l {
        &-0 {
          padding-left: 0;
        }

        &-05 {
          padding-left: 0.5rem;
        }

        &-1 {
          padding-left: 1rem;
        }

        &-2 {
          padding-left: 2rem;
        }

        &-3 {
          padding-left: 3rem;
        }
      }

      &v {
        &-025 {
          padding-top: .25rem;
          padding-bottom: .25rem;
        }

        &-05 {
          padding-top: .5rem;
          padding-bottom: .5rem;
        }

        &-075 {
          padding-top: .75rem;
          padding-bottom: .75rem;
        }

        &-1 {
          padding-top: 1rem;
          padding-bottom: 1rem;
        }

        &-2 {
          padding-top: 2rem;
          padding-bottom: 2rem;
        }

        &-3 {
          padding-top: 3rem;
          padding-bottom: 3rem;
        }
      }

      &h {
        &-025 {
          padding-right: .25rem;
          padding-left: .25rem;
        }

        &-05 {
          padding-right: .5rem;
          padding-left: .5rem;
        }

        &-075 {
          padding-right: .75rem;
          padding-left: .75rem;
        }

        &-1 {
          padding-right: 1rem;
          padding-left: 1rem;
        }

        &-15 {
          padding-right: 1.5rem;
          padding-left: 1.5rem;
        }

        &-2 {
          padding-right: 2rem;
          padding-left: 2rem;
        }

        &-3 {
          padding-right: 3rem;
          padding-left: 3rem;
        }
      }
    }

    &_m {
      &-0 {
        margin: 0 !important;
      }

      &-a {
        margin: auto;
      }

      &v {
        &-025 {
          margin-top: .25rem;
          margin-bottom: .25rem;
        }

        &-05 {
          margin-top: .5rem;
          margin-bottom: .5rem;
        }

        &-1 {
          margin-top: 1rem;
          margin-bottom: 1rem;
        }

        &-15 {
          margin-top: 1.5rem;
          margin-bottom: 1.5rem;
        }

        &-2 {
          margin-top: 2rem;
          margin-bottom: 2rem;
        }
      }

      &h {
        &-a {
          margin-left: auto;
          margin-right: auto;
        }

        &-025 {
          margin-left: .25rem;
          margin-right: .25rem;
        }

        &-05 {
          margin-left: .5rem;
          margin-right: .5rem;
        }

        &-1 {
          margin-left: 1rem;
          margin-right: 1rem;
        }

        &-2 {
          margin-left: 2rem;
          margin-right: 2rem;
        }
      }

      &l {
        &-0 {
          margin-left: 0;
        }

        &-025 {
          margin-left: 0.25rem;
        }

        &-05 {
          margin-left: 0.5rem;
        }

        &-1 {
          margin-left: 1rem;
        }

        &-15 {
          margin-left: 1.5rem;
        }

        &-2 {
          margin-left: 2rem;
        }

        &-3 {
          margin-left: 3rem;
        }

        &-a {
          margin-left: auto;
        }
      }

      &r {
        &-0 {
          margin-right: 0;
        }

        &-025 {
          margin-right: 0.25rem;
        }

        &-05 {
          margin-right: 0.5rem;
        }

        &-1 {
          margin-right: 1rem;
        }

        &-2 {
          margin-right: 2rem;
        }

        &-a {
          margin-right: auto;
        }
      }

      &t {
        &-0 {
          margin-top: 0;
        }

        &-025 {
          margin-top: 0.25rem;
        }

        &-05 {
          margin-top: 0.5rem;
        }

        &-075 {
          margin-top: 0.75rem;
        }

        &-1 {
          margin-top: 1rem;
        }

        &-2 {
          margin-top: 2rem;
        }

        &-3 {
          margin-top: 3rem;
        }

        &-a {
          margin-top: auto;
        }
      }

      &b {
        &-0 {
          margin-bottom: 0;
        }

        &-025 {
          margin-bottom: .25rem;
        }

        &-05 {
          margin-bottom: 0.5rem;
        }

        &-1 {
          margin-bottom: 1rem;
        }

        &-2 {
          margin-bottom: 2rem;
        }

        &-3 {
          margin-bottom: 3rem;
        }

        &-a {
          margin-bottom: auto;
        }
      }
    }

    &_f,
    &_i-f {
      &.c {
        flex-direction: column;
      }

      &-ac {
        align-items: center;
      }

      &-as {
        align-items: start;
      }

      &-je {
        justify-content: end;
      }

      &-jc {
        justify-content: center;
      }

      &-jb {
        justify-content: space-between;
      }

      &-js {
        justify-content: start;
      }
    }

    &_w {
      &-100 {
        width: 100%;
      }
    }

    &_fs {
      &-05 {
        font-size: .5rem;
      }
      &-075 {
        font-size: .75rem;
      }

      &-085 {
        font-size: .85rem;
      }

      &-095 {
        font-size: .95rem;
      }

      &-1 {
        font-size: 1rem;
      }

      &-125 {
        font-size: 1.25rem;
      }

      &-15 {
        font-size: 1.5rem;
      }

      &-175 {
        font-size: 1.75rem;
      }

      &-2 {
        font-size: 2rem;
      }
    }

    .ta {
      &-c {
        text-align: center;
      }

      &-l {
        text-align: left;
      }

      &-r {
        text-align: right;
      }
    }

  }
}

.ms {
  @media screen and (max-width: 720px) {
    &_fd {
      &-c {
        flex-direction: column;
      }
      &-cr {
        flex-direction: column-reverse;
      }
    }

    &_p {
      &-0 {
        padding: 0;
      }

      &-025 {
        padding: .25rem;
      }

      &-05 {
        padding: .5rem;
      }

      &-1 {
        padding: 1rem;
      }

      &-2 {
        padding: 2rem;
      }

      &t {
        &-0 {
          padding-top: 0;
        }

        &-05 {
          padding-top: 0.5rem;
        }

        &-1 {
          padding-top: 1rem;
        }

        &-2 {
          padding-top: 2rem;
        }

        &-3 {
          padding-top: 3rem;
        }
      }

      &r {
        &-0 {
          padding-right: 0;
        }

        &-05 {
          padding-right: 0.5rem;
        }

        &-1 {
          padding-right: 1rem;
        }

        &-2 {
          padding-right: 2rem;
        }

        &-3 {
          padding-right: 3rem;
        }
      }

      &b {
        &-0 {
          padding-bottom: 0;
        }

        &-05 {
          padding-bottom: 0.5rem;
        }

        &-1 {
          padding-bottom: 1rem;
        }

        &-2 {
          padding-bottom: 2rem;
        }

        &-3 {
          padding-bottom: 3rem;
        }

        &-4 {
          padding-bottom: 4rem;
        }
      }

      &l {
        &-0 {
          padding-left: 0;
        }

        &-05 {
          padding-left: 0.5rem;
        }

        &-1 {
          padding-left: 1rem;
        }

        &-2 {
          padding-left: 2rem;
        }

        &-3 {
          padding-left: 3rem;
        }
      }

      &v {
        &-025 {
          padding-top: .25rem;
          padding-bottom: .25rem;
        }

        &-05 {
          padding-top: .5rem;
          padding-bottom: .5rem;
        }

        &-075 {
          padding-top: .75rem;
          padding-bottom: .75rem;
        }

        &-1 {
          padding-top: 1rem;
          padding-bottom: 1rem;
        }

        &-2 {
          padding-top: 2rem;
          padding-bottom: 2rem;
        }

        &-3 {
          padding-top: 3rem;
          padding-bottom: 3rem;
        }
      }

      &h {
        &-025 {
          padding-right: .25rem;
          padding-left: .25rem;
        }

        &-05 {
          padding-right: .5rem;
          padding-left: .5rem;
        }

        &-075 {
          padding-right: .75rem;
          padding-left: .75rem;
        }

        &-1 {
          padding-right: 1rem;
          padding-left: 1rem;
        }

        &-15 {
          padding-right: 1.5rem;
          padding-left: 1.5rem;
        }

        &-2 {
          padding-right: 2rem;
          padding-left: 2rem;
        }

        &-3 {
          padding-right: 3rem;
          padding-left: 3rem;
        }
      }
    }

    &_m {
      &-0 {
        margin: 0 !important;
      }

      &-a {
        margin: auto;
      }

      &v {
        &-025 {
          margin-top: .25rem;
          margin-bottom: .25rem;
        }

        &-05 {
          margin-top: .5rem;
          margin-bottom: .5rem;
        }

        &-1 {
          margin-top: 1rem;
          margin-bottom: 1rem;
        }

        &-15 {
          margin-top: 1.5rem;
          margin-bottom: 1.5rem;
        }

        &-2 {
          margin-top: 2rem;
          margin-bottom: 2rem;
        }
      }

      &h {
        &-a {
          margin-left: auto;
          margin-right: auto;
        }

        &-025 {
          margin-left: .25rem;
          margin-right: .25rem;
        }

        &-05 {
          margin-left: .5rem;
          margin-right: .5rem;
        }

        &-1 {
          margin-left: 1rem;
          margin-right: 1rem;
        }

        &-2 {
          margin-left: 2rem;
          margin-right: 2rem;
        }
      }

      &l {
        &-0 {
          margin-left: 0;
        }

        &-025 {
          margin-left: 0.25rem;
        }

        &-05 {
          margin-left: 0.5rem;
        }

        &-1 {
          margin-left: 1rem;
        }

        &-15 {
          margin-left: 1.5rem;
        }

        &-2 {
          margin-left: 2rem;
        }

        &-3 {
          margin-left: 3rem;
        }

        &-a {
          margin-left: auto;
        }
      }

      &r {
        &-0 {
          margin-right: 0;
        }

        &-025 {
          margin-right: 0.25rem;
        }

        &-05 {
          margin-right: 0.5rem;
        }

        &-1 {
          margin-right: 1rem;
        }

        &-2 {
          margin-right: 2rem;
        }

        &-a {
          margin-right: auto;
        }
      }

      &t {
        &-0 {
          margin-top: 0;
        }

        &-025 {
          margin-top: 0.25rem;
        }

        &-05 {
          margin-top: 0.5rem;
        }

        &-075 {
          margin-top: 0.75rem;
        }

        &-1 {
          margin-top: 1rem;
        }

        &-2 {
          margin-top: 2rem;
        }

        &-3 {
          margin-top: 3rem;
        }

        &-a {
          margin-top: auto;
        }
      }

      &b {
        &-0 {
          margin-bottom: 0;
        }

        &-025 {
          margin-bottom: .25rem;
        }

        &-05 {
          margin-bottom: 0.5rem;
        }

        &-1 {
          margin-bottom: 1rem;
        }

        &-2 {
          margin-bottom: 2rem;
        }

        &-3 {
          margin-bottom: 3rem;
        }

        &-a {
          margin-bottom: auto;
        }
      }
    }

    &_f,
    &_i-f {
      &.c {
        flex-direction: column;
      }

      &-ac {
        align-items: center;
      }

      &-as {
        align-items: start;
      }

      &-je {
        justify-content: end;
      }

      &-jc {
        justify-content: center;
      }

      &-jb {
        justify-content: space-between;
      }

      &-js {
        justify-content: start;
      }
    }

    &_w {
      &-100 {
        width: 100%;
      }
    }

    .fs {
      &-05 {
        font-size: .5rem;
      }

      &-075 {
        font-size: .75rem;
      }

      &-085 {
        font-size: .85rem;
      }

      &-095 {
        font-size: .95rem;
      }

      &-1 {
        font-size: 1rem;
      }

      &-125 {
        font-size: 1.25rem;
      }

      &-15 {
        font-size: 1.5rem;
      }

      &-175 {
        font-size: 1.75rem;
      }

      &-2 {
        font-size: 2rem;
      }
    }

    &_ta {
      &-c {
        text-align: center;
      }

      &-l {
        text-align: left;
      }

      &-r {
        text-align: right;
      }
    }

    &_display {
      &-none {
        display: none;
      }
    }

  }
}

.mxs {
  @media screen and (max-width: 500px) {
    &_fd {
      &-c {
        flex-direction: column;
      }
      &-r {
        flex-direction: row;
      }
      &-cr {
        flex-direction: column-reverse;
      }
    }

    &_p {
      &-0 {
        padding: 0;
      }

      &-025 {
        padding: .25rem;
      }

      &-05 {
        padding: .5rem;
      }

      &-1 {
        padding: 1rem;
      }

      &-2 {
        padding: 2rem;
      }

      &t {
        &-0 {
          padding-top: 0;
        }

        &-05 {
          padding-top: 0.5rem;
        }

        &-1 {
          padding-top: 1rem;
        }

        &-2 {
          padding-top: 2rem;
        }

        &-3 {
          padding-top: 3rem;
        }
      }

      &r {
        &-0 {
          padding-right: 0;
        }

        &-05 {
          padding-right: 0.5rem;
        }

        &-1 {
          padding-right: 1rem;
        }

        &-2 {
          padding-right: 2rem;
        }

        &-3 {
          padding-right: 3rem;
        }
      }

      &b {
        &-0 {
          padding-bottom: 0;
        }

        &-05 {
          padding-bottom: 0.5rem;
        }

        &-1 {
          padding-bottom: 1rem;
        }

        &-2 {
          padding-bottom: 2rem;
        }

        &-3 {
          padding-bottom: 3rem;
        }

        &-4 {
          padding-bottom: 4rem;
        }
      }

      &l {
        &-0 {
          padding-left: 0;
        }

        &-05 {
          padding-left: 0.5rem;
        }

        &-1 {
          padding-left: 1rem;
        }

        &-2 {
          padding-left: 2rem;
        }

        &-3 {
          padding-left: 3rem;
        }
      }

      &v {
        &-025 {
          padding-top: .25rem;
          padding-bottom: .25rem;
        }

        &-05 {
          padding-top: .5rem;
          padding-bottom: .5rem;
        }

        &-075 {
          padding-top: .75rem;
          padding-bottom: .75rem;
        }

        &-1 {
          padding-top: 1rem;
          padding-bottom: 1rem;
        }

        &-2 {
          padding-top: 2rem;
          padding-bottom: 2rem;
        }

        &-3 {
          padding-top: 3rem;
          padding-bottom: 3rem;
        }
      }

      &h {
        &-025 {
          padding-right: .25rem;
          padding-left: .25rem;
        }

        &-05 {
          padding-right: .5rem;
          padding-left: .5rem;
        }

        &-075 {
          padding-right: .75rem;
          padding-left: .75rem;
        }

        &-1 {
          padding-right: 1rem;
          padding-left: 1rem;
        }

        &-15 {
          padding-right: 1.5rem;
          padding-left: 1.5rem;
        }

        &-2 {
          padding-right: 2rem;
          padding-left: 2rem;
        }

        &-3 {
          padding-right: 3rem;
          padding-left: 3rem;
        }
      }
    }

    &_m {
      &-0 {
        margin: 0 !important;
      }

      &-a {
        margin: auto;
      }

      &v {
        &-025 {
          margin-top: .25rem;
          margin-bottom: .25rem;
        }

        &-05 {
          margin-top: .5rem;
          margin-bottom: .5rem;
        }

        &-1 {
          margin-top: 1rem;
          margin-bottom: 1rem;
        }

        &-15 {
          margin-top: 1.5rem;
          margin-bottom: 1.5rem;
        }

        &-2 {
          margin-top: 2rem;
          margin-bottom: 2rem;
        }
      }

      &h {
        &-a {
          margin-left: auto;
          margin-right: auto;
        }

        &-025 {
          margin-left: .25rem;
          margin-right: .25rem;
        }

        &-05 {
          margin-left: .5rem;
          margin-right: .5rem;
        }

        &-1 {
          margin-left: 1rem;
          margin-right: 1rem;
        }

        &-2 {
          margin-left: 2rem;
          margin-right: 2rem;
        }
      }

      &l {
        &-0 {
          margin-left: 0 !important;
        }

        &-025 {
          margin-left: 0.25rem;
        }

        &-05 {
          margin-left: 0.5rem;
        }

        &-1 {
          margin-left: 1rem;
        }

        &-15 {
          margin-left: 1.5rem;
        }

        &-2 {
          margin-left: 2rem;
        }

        &-3 {
          margin-left: 3rem;
        }

        &-a {
          margin-left: auto;
        }
      }

      &r {
        &-0 {
          margin-right: 0;
        }

        &-025 {
          margin-right: 0.25rem;
        }

        &-05 {
          margin-right: 0.5rem;
        }

        &-1 {
          margin-right: 1rem;
        }

        &-2 {
          margin-right: 2rem;
        }

        &-a {
          margin-right: auto;
        }
      }

      &t {
        &-0 {
          margin-top: 0;
        }

        &-025 {
          margin-top: 0.25rem;
        }

        &-05 {
          margin-top: 0.5rem;
        }

        &-075 {
          margin-top: 0.75rem;
        }

        &-1 {
          margin-top: 1rem;
        }

        &-2 {
          margin-top: 2rem;
        }

        &-3 {
          margin-top: 3rem;
        }

        &-a {
          margin-top: auto;
        }
      }

      &b {
        &-0 {
          margin-bottom: 0;
        }

        &-025 {
          margin-bottom: .25rem;
        }

        &-05 {
          margin-bottom: 0.5rem;
        }

        &-1 {
          margin-bottom: 1rem;
        }

        &-2 {
          margin-bottom: 2rem;
        }

        &-3 {
          margin-bottom: 3rem;
        }

        &-a {
          margin-bottom: auto;
        }
      }
    }

    &_f,
    &_i-f {
      &.c {
        flex-direction: column;
      }

      &-ac {
        align-items: center;
      }

      &-as {
        align-items: start;
      }

      &-je {
        justify-content: end;
      }

      &-jc {
        justify-content: center;
      }

      &-jb {
        justify-content: space-between;
      }

      &-js {
        justify-content: start;
      }
    }

    &_w {
      &-100 {
        width: 100%;
      }
    }

    &_fs {
      &-075 {
        font-size: .75rem;
      }

      &-085 {
        font-size: .85rem;
      }

      &-095 {
        font-size: .95rem;
      }

      &-1 {
        font-size: 1rem;
      }

      &-125 {
        font-size: 1.25rem;
      }

      &-15 {
        font-size: 1.5rem;
      }

      &-175 {
        font-size: 1.75rem;
      }

      &-2 {
        font-size: 2rem;
      }
    }

    &_ta {
      &-c {
        text-align: center;
      }

      &-l {
        text-align: left;
      }

      &-r {
        text-align: right;
      }
    }

    &_fs {
      &-05 {
        font-size: .5rem;
      }
      &-075 {
        font-size: .75rem;
      }
      &-1 {
        font-size: 1rem!important;
      }
    }

    &_display {
      &-none {
        display: none;
      }
    }

  }
}